import { createSlice } from "@reduxjs/toolkit";
import { GetTransactionDeposit } from "../../api/transaction";
import TransactionSlice from "./Transaction";
import {
  GetAffReport,
  GetBonusReport,
  GetGameHistory,
  GetSharingReport,
  GetUserById,
  GetUsersReport,
  GetTurnoverReport,
} from "../../api/report";
import { NotificationManager } from "react-light-notifications";
import { GetMe } from "../../api/me";
import AccountUserSlice from "./AccountUsers";
import GlobalSlice from "./Global";

const ReportSlice = createSlice({
  name: "ReportSlice",
  initialState: {
    gameHistory: {
      data: [],
      startTime: "",
      endTime: "",
      page: 1,
      pageSize: 15,
      total: 0,
      sum: {
        bet: 0,
        win: 0,
      },
    },
    sharingReport: {},
    usersReport: [],
    turnoverReport: {},
    bonusReport: {
      data: [],
      total: 0,
      totalAmount: 0,
      totalBonus: 0,
    },
  },
  reducers: {
    getGameHistory: (state, action) => {
      const { data } = action.payload;

      data.data = data.data ? data.data : [];

      return Object.assign(state, {
        gameHistory: data,
      });
    },
    getSharingReport: (state, action) => {
      const { data } = action.payload;

      // data.data = data.data ? data.data : []

      return Object.assign(state, {
        sharingReport: data,
      });
    },
    getUsersReport: (state, action) => {
      const { data } = action.payload;
      return Object.assign(state, {
        usersReport: data,
      });
    },
    updateUsersReport: (state, action) => {
      const { data } = action.payload;
      console.log(data);
      const updatedKeyUser = data;
      const updatedKeyUsers = state.usersReport.users.map((user) => {
        if (user.id === updatedKeyUser.id) {
          user[updatedKeyUser.key] = updatedKeyUser.value;
          return user;
        }
        return user;
      });
      let uReport = state.usersReport;
      uReport.users = updatedKeyUsers;

      return Object.assign(state, {
        usersReport: uReport,
      });
    },
    getBonusReport: (state, action) => {
      const { data } = action.payload;
      return Object.assign(state, {
        bonusReport: data,
      });
    },
    getAffReport: (state, action) => {
      const { data } = action.payload;
      return Object.assign(state, {
        affReport: data,
      });
    },
    getTurnoverReport: (state, action) => {
      const { data } = action.payload;
      return Object.assign(state, {
        turnoverReport: data,
      });
    },
  },
});
export default ReportSlice;

export const resetGameHistory = () => {
  return (dispatch) => {
    dispatch(
      ReportSlice.actions.getGameHistory({
        data: {},
      })
    );
  };
};

export const getGameHistory = (username, page, pageSize, from, to) => {
  return async (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    try {
      const response = await GetGameHistory(username, page, pageSize, from, to);
      if (!response.status >= 400) {
        throw Error(response.statusText);
      }
      const response_1 = response;
      const items = response_1;
      if (items.status === 200) {
        // if (items.data == false) {
        //     NotificationManager.error({
        //         title: "ผิดพลาด",
        //         message: "ไม่พบข้อมูล",
        //     });
        // }
        const dt = items.data.data;
        if (dt.length > 0) {
          dispatch(
            ReportSlice.actions.getGameHistory({
              data: items.data,
              dateCurrent: { from: from, to: to },
            })
          );
        } else {
          NotificationManager.error({
            title: "ผิดพลาด",
            message: "ไม่พบข้อมูล",
          });
        }
      }
    } catch (e) {
      console.log(e);
      NotificationManager.error({
        title: "ผิดพลาด",
        message: "ไม่พบข้อมูล",
      });
      dispatch(
        ReportSlice.actions.getGameHistory({
          data: {},
        })
      );
    }
    dispatch(GlobalSlice.actions.setOnSuccess());
  };
};

export const getSharingReport = (from, to, itemPerPage) => {
  return (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    return GetSharingReport(from, to, itemPerPage)
      .then((response) => {
        if (!response.status >= 400) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response)
      .then((items) => {
        if (items.status === 200) {
          dispatch(
            ReportSlice.actions.getSharingReport({
              data: items.data,
              dateCurrent: { from: from, to: to },
            })
          );
        }
        dispatch(GlobalSlice.actions.setOnSuccess());
      })
      .catch((e) => {
        console.log(e);
        dispatch(GlobalSlice.actions.setOnSuccess());
      });
  };
};

export const getNewUsersReport = (from, to, itemPerPage, page = 1) => {
  return (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    return GetUsersReport(from, to, itemPerPage, page)
      .then((response) => {
        if (!response.status >= 400) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response)
      .then((items) => {
        if (items.status === 200) {
          dispatch(
            ReportSlice.actions.getUsersReport({
              data: items.data,
              dateCurrent: { from: from, to: to },
            })
          );
        }
        dispatch(GlobalSlice.actions.setOnSuccess());
      })
      .catch((e) => {
        console.log(e);
        dispatch(GlobalSlice.actions.setOnSuccess());
      });
  };
};

export const getUserDetailReport = (id) => {
  return (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    return GetUserById(id)
      .then((response) => {
        if (!response.status >= 400) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response)
      .then((items) => {
        if (items.status === 200) {
          const resp = {
            id: id,
            key: "balance",
            value: items.data.balance,
          };
          console.log(resp);
          dispatch(ReportSlice.actions.updateUsersReport({ data: resp }));
        }
        dispatch(GlobalSlice.actions.setOnSuccess());
      })
      .catch((e) => {
        console.log(e);
        dispatch(GlobalSlice.actions.setOnSuccess());
      });
  };
};

export const getBonusReport = (_page, _pageSize = 15, _from, _to, username) => {
  return async (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    try {
      const response = await GetBonusReport(
        _page,
        _pageSize,
        _from,
        _to,
        username
      );
      if (!response.status >= 400) {
        throw Error(response.statusText);
      }

      if (response.status === 200) {
        dispatch(
          ReportSlice.actions.getBonusReport({
            data: response.data,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(GlobalSlice.actions.setOnSuccess());
  };
};

export const getAffReport = () => {
  return async (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());
    try {
      const response = await GetAffReport();
      if (!response.status >= 400) {
        throw Error(response.statusText);
      }

      if (response.status === 200) {
        console.log(response.data);
        dispatch(
          ReportSlice.actions.getAffReport({
            data: response.data,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
    dispatch(GlobalSlice.actions.setOnSuccess());
  };
};

export const getTurnoverReport = (userId) => {
  return async (dispatch) => {
    dispatch(GlobalSlice.actions.setOnLoading());

    try {
      const response = await GetTurnoverReport(userId);
      if (response.status === 200) {
        dispatch(
          ReportSlice.actions.getTurnoverReport({
            data: response.data,
          })
        );
      } else {
        throw Error(response.statusText);
      }
    } catch (e) {
      console.log(e);
      // You may dispatch an error action or handle errors as per your requirement
    }
    dispatch(GlobalSlice.actions.setOnSuccess());

  };
};
